import { init } from '@rematch/core';
import auth from './models/auth';
import user from './models/user';
import video from './models/video';
import team from './models/team';
import sportPlace from './models/sportPlace';
import tournament from './models/tournament';
import match  from './models/match';
const store = init({
  redux: {
    rootReducers: {
      RESET_APP: () => undefined,
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
  models: {
    auth,
    user,
    video,
    team,
    sportPlace,
    tournament,
    match,
  },
});

export default store;
