import ax from '../../axios';

const video = {
  state: {
    list: null,
    error: null,
    weeks: null,
    views: null,
    matches: null,
    status: '',
    excelUrl: null,
  },
  reducers: {
    report: (state) => ({ ...state, status: 'waiting', error: null }),

    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    create: (state) => ({ ...state, status: 'waiting', error: null }),
    createSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createFailed: (state, error) => ({ ...state, status: 'failed', error }),

  },
  effects: (dispatch) => ({
    create(payload) {
      const { values, enqueueSnackbar,setIsSaving } = payload;
      console.log(values);

      ax.post('/save-video', values).then((res) => {
        enqueueSnackbar('Video guardado correctamente', { variant: 'success' });
        dispatch.video.createSuccess(res);
        setIsSaving(false);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    report(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/report', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    
  }),
};

export default video;
