/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Router, Redirect } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  SignIn, Error, Layout,UploadVideo,CreateMatch,ReportExtractor
} from './components';

const App = () => {
  const ls = localStorage.getItem('session');
  const session = useMemo(() => JSON.parse(ls), [ls]);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const mainRoutes = (
    <>
      <Redirect from='/' to='veoCam' noThrow />
      <Layout path='/'>
        <UploadVideo path='veoCam' />

        <CreateMatch path="createMatch" />
        <ReportExtractor path="/report" />
        <Error default />
      </Layout>
    </>
  );

  React.useEffect(() => {
    if (session) {
      // recovered session, saved on redux store
      dispatch({ type: 'auth/recoverUserSession', payload: session });
    }
  }, [dispatch, session]);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          {(session && session.token) || token
            ? mainRoutes
            : <SignIn path='/' />}

          <Error default />
        </Router>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
};

export default App;
