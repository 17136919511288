import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const team = {
  state: {
    list: null,
    error: null,
    status: '',
    excelUrl: null,
  },
  reducers: {
    // Count users by team with sport place info
    withSportPlace: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel with teams info
    excel: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    //julio
    wallet: (state) => ({ ...state, status: 'waiting', error: null }),
    
  },
  effects: (dispatch) => ({
    withSportPlace(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-sport-place', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    wallet(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-wallet', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    activity(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-activity', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excel(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-sport-place', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'Equipos.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    excelWallet(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-wallet', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'wallet_teams.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelActivity(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-activity', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'teams_activity.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
  }),
};

export default team;
