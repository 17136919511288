import ax from '../../axios';

const sportPlace = {
  state: {
    list: null,
    tournaments: null,
    error: null,
    courts:null,
    locations: null,
    status: '',
    watermark: null
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Active tournaments
    activeTournaments: (state) => ({ ...state, status: 'waiting', error: null }),
    tournamentsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      tournaments: res,
    }),
    tournamentsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    courtsSportPlace: (state) => ({ ...state, status: 'waiting', error: null }),
    courtsSportPlaceSuccess: (state, res) => ({
      ...state,
      status: 'received',
      courts: res,
    }),
    courtsSportPlaceFailed: (state, error) => ({ ...state, status: 'failed', error }),

    locations: (state) => ({ ...state, status: 'waiting', error: null }),
    locationsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      locations: res,
    }),
    locationsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    watermark: (state) => ({ ...state, status: 'waiting', error: null }),
    watermarkSuccess: (state, res) => ({
      ...state,
      status: 'received',
      watermark: res,
    }),
    watermarkFailed: (state, error) => ({ ...state, status: 'failed', error }),
    
  },
  effects: (dispatch) => ({
    list(payload) {
      const { enqueueSnackbar } = payload;

      ax.post('/catalogs/sport-places').then((res) => {
        dispatch.sportPlace.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
 
    activeTournaments(payload) {
      const { sportPlaceId, enqueueSnackbar } = payload;

      
      ax.post(`/catalogs/tournaments/${sportPlaceId}`).then((res) => {
        dispatch.sportPlace.tournamentsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.tournamentsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    courtsSportPlace(payload) {
      const { sportPlaceId, enqueueSnackbar } = payload;

      
      ax.post(`/catalogs/courts/${sportPlaceId}`).then((res) => {
        dispatch.sportPlace.courtsSportPlaceSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.courtsSportPlaceFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    locations(payload) {
      const { enqueueSnackbar } = payload;

      
      ax.post(`/catalogs/locations`).then((res) => {
        dispatch.sportPlace.locationsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.locationsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    watermark(payload) {
      const { enqueueSnackbar } = payload;

      
      ax.post(`/catalogs/watermark`).then((res) => {
        dispatch.sportPlace.watermarkSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.watermarkFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    
  }),
};

export default sportPlace;
