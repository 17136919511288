import ax from '../../axios';

const tournament = {
  state: {
    list: null,
    sets: null,
    teams: null,
    error: null,
    status: '',
  },
  reducers: {
    teams: (state) => ({ ...state, status: 'waiting', error: null }),
    teamsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      teams: res,
    }),
    teamsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Active tournaments
    sets: (state) => ({ ...state, status: 'waiting', error: null }),
    setsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      sets: res,
    }),
    setsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    
  },
  effects: (dispatch) => ({
    teams(payload) {
        const { tournamentId, enqueueSnackbar } = payload;
  
        
        ax.post(`/catalogs/teams/${tournamentId}`).then((res) => {
          dispatch.tournament.teamsSuccess(res.data);
        })
          .catch((error) => {
            let msg = error.message;
            if (error.response) {
              msg = error.response.data.message;
            }
            dispatch.tournament.teamsFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
          });
      },
    sets(payload) {
      const { tournamentId, enqueueSnackbar } = payload;

      
      ax.post(`/catalogs/sets/${tournamentId}`).then((res) => {
        dispatch.tournament.setsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.tournament.setsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    
  }),
};

export default tournament;
