import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
// import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PeopleIcon from '@material-ui/icons/People';
import logo from '../assets/img/logo.png';

const AppDrawer = (props) => {
  const { open, toggleDrawer } = props;

  function handleUrlChage(url) {
    navigate(url);
    toggleDrawer();
  }

  return (
    <Drawer anchor='left' open={open} onClose={toggleDrawer}>
      <div className='w-64' role='presentation'>
        <header className='px-4 py-6'>
          <img className='w-20' src={logo} alt='PITZ' />
        </header>
        <Divider className='mb-4' />

        <List>
          <ListItem>
            <ListItemText className='opacity-75' primary='VeoCam' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/veoCam')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Cargar de videos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/createMatch')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Crear Partido' />
          </ListItem>


          <ListItem button onClick={() => handleUrlChage('/report')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Reportes' />
          </ListItem>

        </List>
      </div>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

AppDrawer.defaultProps = {
  open: false,
  toggleDrawer: () => null,
};

export default AppDrawer;
