export default class Utils {
  static mapUrlParams(str) {
    return str.split('&').reduce((prev, curr) => {
      const param = curr.split('=');
      // eslint-disable-next-line no-param-reassign
      prev[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
      return prev;
    }, {});
  }

  static bytesToSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
