import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ReportExtractor = () => {


  const [ setReturnVideoId] = useState(null);


  const { enqueueSnackbar } = useSnackbar();
  const auditionState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    list: state.video.list,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
  }); 

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };




  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/report', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


    const handleReturnVideoClick = (id) => {
     setReturnVideoId(id);
    }



  return (
    <main>


      {auditionState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Reporte  
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
      <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>
        
       
      </div>

  

      

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
              <StyledTableCell>Total de partidos: {auditionState.list?.meta?.total || 0} </StyledTableCell>

              </TableRow>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell>Torneo</StyledTableCell>
                <StyledTableCell># Videos</StyledTableCell>
                
                
              </TableRow>
            </TableHead>
            <TableBody>
              {auditionState.list?.data?.map((row, i) => <Row key={i} handleReturnVideoClick={handleReturnVideoClick} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={auditionState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={auditionState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {

  return (
    <React.Fragment>
      <StyledTableRow>

        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.team_a_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.team_b_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.tournament_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.video_highlight.length}</StyledTableCell>

        
      </StyledTableRow>
     
      
    </React.Fragment>
  );
}

export default ReportExtractor;
