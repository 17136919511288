import React, { useEffect } from 'react';
import {
  Typography, Paper, LinearProgress, Select, InputLabel,
  FormControl, FormHelperText, Button, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      width: '25ch',
    },
  },
}));

const CreateMatch = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, reset } = useForm({ submitFocusError: true });
  const assignState = useSelector((state) => ({
    status: state.sportPlace.status,
    error: state.sportPlace.error,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,
    courts: state.sportPlace.courts,
    locations: state.sportPlace.locations,
    teams: state.tournament.teams,
  }));
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    dispatch({ type: 'match/createMatch', payload: { values, enqueueSnackbar } });
  };

  const handleChangeSportPlace = (event) => {
    const sportPlaceId = event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments', payload: { sportPlaceId, enqueueSnackbar } });
    dispatch({ type: 'sportPlace/courtsSportPlace', payload: { sportPlaceId, enqueueSnackbar } });
  };

  const handleChangeTournament = (event) => {
    const tournamentId = event.target.value;
    dispatch({ type: 'tournament/sets', payload: { tournamentId, enqueueSnackbar } });
    dispatch({ type: 'tournament/teams', payload: { tournamentId, enqueueSnackbar } });
  };

  useEffect(() => {
    if (assignState.status === 'assigned')
      reset({});
    // eslint-disable-next-line
  }, [assignState.status]);

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    dispatch({ type: 'sportPlace/locations', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      {assignState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Crear Partido
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <form className={classes.root} onSubmit={handleSubmit(onSubmitHandler)}>
          <FormControl error={errors.sport_place_id} fullWidth={true}>
            <InputLabel htmlFor='sport_place_id'>Cuentas</InputLabel>
            <Select 
              label='Cuentas' 
              id='sport_place_id' 
              native 
              name='sport_place_id'
              onChange={handleChangeSportPlace}
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona complejo' value='' />
              {assignState.sportPlaces?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.sport_place_id?.message}</FormHelperText>
          </FormControl>

          <FormControl error={errors.tournament_id} fullWidth={true}>
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select 
              label='Torneo' 
              id='tournament_id' 
              native 
              onChange={handleChangeTournament}
              name='tournament_id'
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona Torneo' value='' />
              {assignState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
          </FormControl>

          <FormControl error={errors.court_id} fullWidth={true}>
            <InputLabel htmlFor='court_id'>Canchas</InputLabel>
            <Select 
              label='Canchas' 
              id='court_id' 
              native 
              name='court_id'
              inputProps={{
                ref: register({
                  
                }),
              }}>
                <option aria-label='Selecciona Cancha' value='' />
              {assignState.courts?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.court_id?.message}</FormHelperText>
          </FormControl>

          

          <FormControl error={errors.location_id} fullWidth={true}>
            <InputLabel htmlFor='location_id'>Locaciones</InputLabel>
            <Select 
              label='Locations' 
              id='location_id' 
              native 
              name='location_id'
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona Locacion' value='' />
              {assignState.locations?.data.map((item,index) => (
                <option value={item.id} key={item.id}>
                {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.team_a_id?.message}</FormHelperText>
          </FormControl>

          

         



            <FormControl error={errors.team_a_id} fullWidth={true}>
                <InputLabel htmlFor='team_a_id'>Team A</InputLabel>
                <Select 
                label='team_a_id' 
                id='team_a_id' 
                native 
                name='team_a_id'
                inputProps={{
                  ref: register({
                    required: 'El campo es requerido.',
                  }),
                }}>
                    <option aria-label='Selecciona Team A' value='' />
                {assignState.teams?.data.map((item,index) => (
                    <option value={item.id} key={item.id}>
                    {item.name}
                    </option>
                ))}
                </Select>
                <FormHelperText>{errors.team_a_id?.message}</FormHelperText>
            </FormControl>

            <FormControl error={errors.team_b_id} fullWidth={true}>
                <InputLabel htmlFor='team_b_id'>Team B</InputLabel>
                <Select 
                label='team_b_id' 
                id='team_b_id' 
                native 
                name='team_b_id'
                inputProps={{
                ref: register({
                    required: 'El campo es requerido.',
                  }),
                }}>
                    <option aria-label='Selecciona Team B' value='' />
                {assignState.teams?.data.map((item,index) => (
                    <option value={item.id} key={item.id}>
                    {item.name}
                    </option>
                ))}
                </Select>
                <FormHelperText>{errors.team_b_id?.message}</FormHelperText>
            </FormControl>

            <FormControl error={errors.date} fullWidth={true}>
                <TextField
                  id="date"
                  label="Fecha y hora"
                  type="datetime-local"
                  name="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}

                  inputProps={{
                    ref: register({
                      required: 'El campo es requerido.',
                    }),
                  }}
                />
                <FormHelperText>{errors.date?.message}</FormHelperText>
            </FormControl>

            <div className={classes.root}>
                <Button onClick={reset} color='secondary' variant="outlined">
                Limpiar datos
                </Button>
                <Button
                color='primary'
                variant="contained"
                disabled={assignState.status === 'waiting'}
                type='submit'
                >
                Crear Partido
                </Button>
            </div>


        </form>
      </div>

    
    </main>
  );
};

export default CreateMatch;
