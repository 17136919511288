import React, { useEffect,useState } from 'react';
import {
  Typography, Paper, LinearProgress, Select, InputLabel,
  FormControl, FormHelperText,Grid,Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import FileUpload from '../FileUpload/FileUpload';
import ImageIcon from '@material-ui/icons/Image';


const UploadVideo = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, reset,setValue, handleSubmit, unregister} = useForm({ submitFocusError: true });
  const assignState = useSelector((state) => ({
    status: state.match.status,
    error: state.match.error,
    matches: state.match.list,
    watermark: state.sportPlace.watermark,
    
  }));
  const [isSaving, setIsSaving] = useState(false);

  const [paginationParams, setPaginationParams] = useState({
    teamA: 'Team A',
    teamB: 'Team B',
    team_a_id: '',
    team_b_id: '',
    match_id: '',
    watermark_id: '',
    type_a: 'gol',
    type_b: 'gol',
    has_applause_a: 0,
    has_applause_b: 0,
  });

 
  const setFieldValue = (name, value) => {
    setValue('video', value);
  };
  const handleChangeMatch = (event) => {
    const match = event.target.value;

    const arrayfind = assignState.matches.data.find(function(item){
      return parseInt(item.id) === parseInt(match)
    });

    setPaginationParams((st) => ({...st, teamA: arrayfind.team_a?.name}));
    setPaginationParams((st) => ({...st, teamB: arrayfind.team_b?.name}));
    setPaginationParams((st) => ({...st, match_id: match}));
    setPaginationParams((st) => ({...st, team_a_id: arrayfind.team_a?.id}));
    setPaginationParams((st) => ({...st, team_b_id: arrayfind.team_b?.id}));

  };


  const handleChangeApplause = (event) => {

    const id = event.target.id ;
    const has_applause = event.target.value;

    if(id === "has_applause_a"){
      setPaginationParams((st) => ({...st, has_applause_a: parseInt(has_applause)}));

    }else{
      setPaginationParams((st) => ({...st, has_applause_b: parseInt(has_applause)}));
    }

  }


  const handleChangeType = (event) => {

    const id = event.target.id ;
    const type = event.target.value;

    if(id === "type_a"){
      setPaginationParams((st) => ({...st, type_a: type}));

    }else{
      setPaginationParams((st) => ({...st, type_b: type}));
    }

  }

  useEffect(() => {
    register({ name: 'video' });
    return () => {
      unregister({ name: 'video' });
    };
  }, [register, unregister]);

  const onSubmit = (data) => {
    const values = setValuesForRequest(data,'A');

    setIsSaving(true);
    dispatch({ type: 'video/create', payload: { values, enqueueSnackbar,setIsSaving:setIsSaving } });
  }

  const onSubmit2 = (data) => {
    const values = setValuesForRequest(data,'B');
    setIsSaving(true);
    dispatch({ type: 'video/create', payload: { values, enqueueSnackbar,setIsSaving:setIsSaving } });
  }

  const setValuesForRequest = (data,type) => {
    let values = Object.assign({}, data);

    values.match_id = paginationParams.match_id;

    if(type === 'A'){
      values.team_id = paginationParams.team_a_id;
      values.type = paginationParams.type_a;
      values.has_applause = paginationParams.has_applause_a;
    }else{
      values.team_id = paginationParams.team_b_id;
      values.type = paginationParams.type_b;
      values.has_applause = paginationParams.has_applause_b;
    }

    return jsonToFormData(values);
  }

  const jsonToFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
      buildFormData(formData, value, key);
    }

    return formData;
  }

  const buildFormData = (formData, data, parentKey) => {
    if(data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      })
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  useEffect(() => {
    if (assignState.status === 'assigned')
      reset({});
    // eslint-disable-next-line
  }, [assignState.status]);

  useEffect(() => {
    dispatch({ type: 'match/list', payload: { enqueueSnackbar } });
    dispatch({ type: 'sportPlace/watermark', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      {assignState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Subir videos
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
          <FormControl error={errors.match_id} fullWidth={true}>
            <InputLabel htmlFor='match_id'>Partido</InputLabel>
            <Select 
              label='Partido' 
              id='match_id' 
              native 
              name='match_id'
              onChange={handleChangeMatch}
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona complejo' value='' />
              {assignState.matches?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.match}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.match_id?.message}</FormHelperText>
          </FormControl>
          
      </div>



      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>


        <Grid container spacing={2}>
          <Grid item xs={6}>
              <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <div className='relative max-w-screen-xl'>
                  <Typography className='m-0 text-white opacity-75  mb-3' variant='h4' component='h2'>
                    {paginationParams.teamA}
                  </Typography>

                  <FormControl error={errors.has_applause_a} fullWidth={true} className='mb-5'>
                    <InputLabel htmlFor='has_applause_a'>¿EL VIDEO MERECE NARRACIÓN?</InputLabel>
                    <Select label='Partido' 
                      id='has_applause_a' 
                      native 
                      name='has_applause_a'
                      onChange={handleChangeApplause}
                    >
                      <option value="0">NO</option>
                      <option value="1">SI</option>
                      
                    </Select>
                    <FormHelperText>{errors.has_applause_a?.message}</FormHelperText>
                  </FormControl>

                  <FormControl error={errors.type_a} fullWidth={true} className='mb-5'>
                    <InputLabel htmlFor='type_a'>Tipo de jugada</InputLabel>
                    <Select label='jugada' 
                      id='type_a' 
                      native 
                      name='type_a'
                      onChange={handleChangeType}
                    >
                      <option value="gol">Gol</option>
                      <option value="chance">Oportunidad</option>
                      <option value="foul">Falta</option>
                      <option value="play">Jugada</option>
                      <option value="save">Atajada</option>
                      
                    </Select>
                    <FormHelperText>{errors.type_a?.message}</FormHelperText>
                  </FormControl>

                  <FormControl fullWidth={true} className='mb-5 text-white'>
                    <FileUpload
                      className='text-white'
                      label='Video'
                      id="video_a"
                      name="video"
                      icon={<ImageIcon className='w-12 h-12 mb-2 text-white' />}
                      fileChange={setFieldValue}
                      accept='.mp4'
                    />
                  </FormControl>
                  <Button type='submit' disabled={isSaving} variant='contained' color='primary'>
                    {isSaving ? 'Guardando...' : 'Guardar'}
                  </Button>
                </div>
              </form>
          </Grid>
          <Grid item xs={6}>
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit2)}>

              <div className='relative max-w-screen-xl'>
                <Typography className='m-0 text-white opacity-75  mb-3' variant='h4' component='h2'>
                  {paginationParams.teamB}
                </Typography>

                <FormControl error={errors.has_applause_b} fullWidth={true} className='mb-5'>
                  <InputLabel htmlFor='has_applause_b'>¿EL VIDEO MERECE NARRACIÓN?</InputLabel>
                  <Select label='Partido' 
                    id='has_applause_b' 
                    native 
                    name='has_applause_b'
                    onChange={handleChangeApplause}
                   >
                    <option value="0">NO</option>
                    <option value="1">SI</option>
                    
                  </Select>
                  <FormHelperText>{errors.has_applause_b?.message}</FormHelperText>
                </FormControl>

                <FormControl error={errors.type_b} fullWidth={true} className='mb-5'>
                  <InputLabel htmlFor='type_b'>Tipo de jugada</InputLabel>
                  <Select label='jugada' 
                    id='type_b' 
                    native 
                    name='type_b'
                    onChange={handleChangeType}
                   >
                    <option value="gol">Gol</option>
                    <option value="chance">Oportunidad</option>
                    <option value="foul">Falta</option>
                    <option value="play">Jugada</option>
                    <option value="save">Atajada</option>
                    
                  </Select>
                  <FormHelperText>{errors.type_b?.message}</FormHelperText>
                </FormControl>

                <FormControl fullWidth={true} className='mb-5 text-white'>
                    <FileUpload
                      className='text-white'
                      label='Video'
                      id="video_b"
                      name="video"
                      icon={<ImageIcon className='w-12 h-12 mb-2 text-white' />}
                      fileChange={setFieldValue}
                      accept='.mp4'
                    />
                  </FormControl>

                  <Button type='submit' disabled={isSaving} variant='contained' color='primary'>
                    {isSaving ? 'Guardando' : 'Guardar Team B'}
                  </Button>
              </div>
            </form>
            
          </Grid>
          
        </Grid>

          

        
      </div>
      
    </main>
  );
};

export default UploadVideo;
