import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import App from './App';
import * as serviceWorker from './serviceWorker';
import muiTheme from './muiTheme';
import store from './store/store';
import ax from './axios';
import './assets/styles/tailwind.css';

const { dispatch } = store;
const UNAUTHORIZED = 401;
const app = (
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);

ax.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === UNAUTHORIZED && error.response.config.url !== '/login') {
      dispatch.auth.signout();
    }

    return Promise.reject(error);
  },
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
