import ax from '../../axios';
import { navigate } from '@reach/router';
const match = {
  state: {
    list: null,
    sets: null,
    teams: null,
    error: null,
    status: '',
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    createMatch: (state) => ({ ...state, status: 'waiting', error: null }),
    createMatchSuccess: (state, res) => ({
      ...state,
      status: 'assigned',
      message: res.message,
    }),
    createMatchFailed: (state, error) => ({ ...state, status: 'failed', error }),
    
  },
  effects: (dispatch) => ({
    list(payload) {
      const { enqueueSnackbar } = payload;

      ax.post('/list-match').then((res) => {
        dispatch.match.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.match.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    createMatch(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/catalogs/create-match', values).then((res) => {
        dispatch.match.createMatchSuccess(res.data);
        enqueueSnackbar('Partido creado', { variant: 'success' });

        navigate('/veoCam')
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.match.createMatchFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    
  }),
};

export default match;
